/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-11-29 10:33:16
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-13 09:18:16
 */
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Card,
  Grid,
  List,
  Picker,
  Popup,
  PullToRefresh,
  Swiper,
  Toast,
  Ellipsis,
  DotLoading,
} from 'antd-mobile'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd-mobile'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { AppDispatch } from '../../app/store'
import styles from './Home.module.css'
import { useLocation } from 'react-router-dom'
import home1 from '../../components/images/home.png'
import rightIconArrow from '../../components/images/rightIconArrow.png'
import rightIconArea from '../../components/images/rightIconArea.png'
import rightIconPhone from '../../components/images/rightIconPhone.png'
import centerLeftIcon from '../../components/images/centerLeftIcon.png'
import centerRightIcon from '../../components/images/centerRightIcon.png'
import centerBackLeftIcon from '../../components/images/centerBackLeftIcon.png'
import centerBackRightIcon from '../../components/images/centerBackRightIcon.png'
import boyHeadImg from '../../components/images/headImgBoy.png'
import girlHeadImg from '../../components/images/headImgGirl.png'
import articalOne from '../../components/images/articalOne.png'
import articalTwo from '../../components/images/articalSecond.png'
import articalThree from '../../components/images/articalThird.png'
import wx from 'weixin-js-sdk' // 引入微信js-sdk
import { selectTenantKey, selectOpenId, setCredentials, selectAccessToken, selectAppId } from '../../app/appSlice'
import {
  getDoctorList,
  getHomeDetail,
  getWechatJsSdkSignature,
  getDefaultPatient,
  getDefaultPatientCode,
  updateDeafaultPatient
} from './HomeSlice'
import { AddCircleOutline } from 'antd-mobile-icons'

export const Home = () => {
  const dispatch = useDispatch<AppDispatch>()

  const history = useHistory()

  const data: any = history.location.state || JSON.parse(sessionStorage.getItem('patientListId') as any)

  const tagList = ['霓虹', '的话v就', '的话v就', '的话v就']

  const [visible, setVisible] = useState(false)

  const [homeData, setHomeData] = useState<any>()

  const [doctorDetail, setDoctorDetail] = useState<any>()

  const location = useLocation()

  const appId = useSelector(selectAppId)

  // const tenantKey = useSelector(selectTenantKey)

  const accessToken = useSelector(selectAccessToken)

  const openid = useSelector(selectOpenId)

  const [defaultPeople, setDefaultPeople] = useState<any>()

  const [qrCode, setQrCode] = useState<any>()

  const [isLoading, setIsLoading] = useState(false)

  const [isLoadingTwo, setIsLoadingTwo] = useState(false)

  const tabList = [{ id: 1, name: '预约挂号' }, { id: 2, name: '联系我们' }, { id: 3, name: '诊所地址' }, { id: 4, name: '诊所介绍' }]

  const queryParams = new URLSearchParams(window.location.search)
  const state = queryParams.get('state')

  const tenantKey = useSelector(selectTenantKey)

  useEffect(() => {
    localStorage.removeItem('doctorId')
  }, [])

  useEffect(() => {

    let key = tenantKey
    if (state) {
      key = state
    }

    if (key) {
      setIsLoadingTwo(true)
      dispatch(getHomeDetail({ tenantKey: key }))
        .then(unwrapResult)
        .then((res) => {
          if (JSON.stringify(res) == '{}') {
            alert('机构在线服务正在建设中，敬请期待！')
            window.close()
          } else {
            setHomeData(res)
            dispatch(getDoctorList({ tenantKey: key, limit: 5 }))
              .then(unwrapResult)
              .then((res: any) => {
                const href = window.location.origin?.includes('localhost')
                  ? 'https://patienth5.hydhis.cn'
                  : window.location?.origin
                setDoctorDetail(
                  res?.map((v: any) => {
                    const headPhotoList = v?.headPhoto?.split('/')
                    return {
                      ...v,
                      headPhoto:
                        v.headPhoto && headPhotoList?.[0] == 'proxyToOss'
                          ? href + '/' + v.headPhoto
                          : v.headPhoto,
                    }
                  })
                )
                setIsLoadingTwo(false)
              })
          }
        }).catch(() => {
        })
    }
  }, [])

  // useEffect(() => {
  //   wx.ready(function () {
  //     // config信息验证后会执行 ready 方法，所有接口调用都必须在 config 接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在 ready 函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在 ready 函数中。
  //     wx.checkJsApi({
  //       jsApiList: ['openLocation'], // 需要检测的 JS 接口列表，所有 JS 接口列表见附录2,
  //       success: function (res: any) {
  //         alert('支持的sdk列表：' + JSON.stringify(res))
  //       },
  //     })
  //   })
  //   wx.error(function (res: any) {
  //     alert('验签失败：' + JSON.stringify(res))
  //   })
  // }, [])

  useEffect(() => {

    let key = tenantKey
    if (state) {
      key = state
    }

    if (key && homeData) {

      localStorage.setItem('tenantName',homeData.name);
      const data = {
        currUrl: window.location.href.split('#')[0],
        appid: appId,
      }
      dispatch(getWechatJsSdkSignature(data))
        .then(unwrapResult)
        .then((res: any) => {
          wx.config({
            debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
            appId: appId, // 必填，公众号的唯一标识
            timestamp: res.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.noncestr, // 必填，生成签名的随机串
            signature: res.signature, // 必填，签名
            jsApiList: ['openLocation', 'updateAppMessageShareData', 'updateTimelineShareData'], // 必填，需要使用的 JS 接口列表
          })

          wx.ready(function () {   //需在用户可能点击分享按钮前就先调用

            wx.updateAppMessageShareData({
              title: homeData.name, // 分享标题
              desc: '', // 分享描述
              link: window.location.href.split('?')[0] + '?share=true&state='+key, // 分享链接，
              imgUrl: '1111', // 分享图标
              success: function () {
                // 设置成功
              }
            })

            wx.updateTimelineShareData({
              title: homeData.name, // 分享标题
              link: window.location.href.split('?')[0] + '?share=true&state='+key, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: '1111', // 分享图标
              success: function () {
                // 设置成功
              }
            })
          });
        })
    }
  }, [homeData])

  useEffect(() => {
    Toast.show({
      content: '加载中...',
      icon: 'loading',
      duration: 5000,
      maskClickable: false
    })

    let key = tenantKey
    if (state) {
      key = state
    }

    setIsLoading(true)
    dispatch(data?.id ? updateDeafaultPatient({ tenantKey: key as string, openid: openid, id: data?.id }) : getDefaultPatient({ tenantKey: key as string, openid: openid }))
      .then(unwrapResult)
      .then((v: any) => {
        setDefaultPeople(v)
        if (v?.patientId) {
          dispatch(getDefaultPatientCode({ content: v?.patientId }))
            .then(unwrapResult)
            .then((res) => {
              setQrCode('data:image/png;base64,' + res)
              setIsLoading(false)
              Toast.clear()
            })
          localStorage.setItem('patientId', v?.patientId)
        } else {
          localStorage.removeItem('patientId')
          setIsLoading(false)
          Toast.clear()
        }
      })
  }, [])

  const pictureList = homeData?.pictureList.map((v: any) => (
    <Swiper.Item key={v?.id}>
      <div className={styles.swiperContent}>
        <img src={v.info} alt='' style={{ width: '100%', height: '187px' }} />
      </div>
    </Swiper.Item>
  ))

  const morenPictureList = (
    <Swiper.Item key={home1}>
      <div className={styles.swiperContent}>
        <img src={home1} alt='' style={{ width: '100%', height: '187px' }} />
      </div>
    </Swiper.Item>
  )

  return (
    <div className={styles.home}>
      <div className={styles.header}>
        {/* <Swiper autoplay>
          {homeData?.pictureList?.length ? pictureList : morenPictureList}
        </Swiper> */}
        <div className={styles.title}>{homeData?.name}</div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Card className={styles.peopleContent}>
            {
              isLoading ? (
                <></>
              ) :
                defaultPeople?.patientId ?
                  (<div className={styles.people}>
                    <div className={styles.peopleContentLeft}>
                      <div className={styles.peopleNameBox}>
                        <div className={styles.peopleName}>{defaultPeople?.name}</div>
                        <div className={styles.peopleNameChange} onClick={() => {
                          history.push({
                            pathname: '/visitorsList',
                            state: {
                              tag: 1
                            }
                          })
                        }}>
                          <div className={styles.changeIcon}></div>
                          <div className={styles.changeText}>切换</div>
                        </div>
                      </div>
                      <div className={styles.visitCard}>
                        <div className={styles.visitCardTitle}>就诊卡</div>
                        <div className={styles.visitCardValue}>{defaultPeople?.patientId}</div>
                      </div>
                    </div>
                    <div className={styles.peopleContenRight} onClick={() => {
                      history.push({
                        pathname: '/patientDetail',
                        state: {
                          id: defaultPeople?.id
                        }
                      })
                    }}>
                      <img src={qrCode} style={{ width: '100%', height: '100%' }} alt="" />
                    </div>
                    <div className={styles.doctorIcon}>
                    </div>
                  </div>) : (<div className={styles.noPeople} onClick={() => {
                    history.push({
                      pathname: '/addPatientPeople',
                    })
                  }}>
                    <AddCircleOutline fontSize={18} color='#999' />
                    <span style={{ marginLeft: '10px' }}>添加/完善就诊人</span>
                  </div>)
            }
          </Card>
        </div>
      </div>
      <div style={{
        width: '100%', display: 'flex', justifyContent: 'center', position: 'relative',
        top: '-25px'
      }}>
        <Card className={styles.titleContent}>
          <div className={styles.titleTagContent}>
            <div className={styles.actionTabs}>
              {tabList.map((v: any) => {
                return (
                  <div className={styles.actionTabsItem} onClick={() => {
                    if (v.id === 1) {
                      history.push({
                        pathname: '/onlineAppointment',
                      })
                    } else if (v.id === 2) {
                      setVisible(true)
                    } else if (v.id === 3) {
                      wx.openLocation({
                        latitude: Number(homeData?.latitude), // 纬度，浮点数，范围为90 ~ -90
                        longitude: Number(homeData?.longitude), // 经度，浮点数，范围为180 ~ -180。
                        name: homeData?.name, // 位置名
                        address: homeData?.address, // 地址详情说明
                        scale: 14, // 地图缩放级别,整型值,范围从1~28。默认为最大
                      })
                    } else if (v.id === 4) {
                      history.push({
                        pathname: '/clinicOverview'
                      })
                    }

                  }}>
                    <div className={v.id === 1 ? styles.yuyueItem
                      : v.id === 2 ? styles.lianxiItem
                        : v.id === 3 ? styles.zhensuoItem
                          : v.id === 4 ? styles.adressItem
                            : ''}></div>
                    <div className={styles.actionTabsItemTitle}>{v.name}</div>
                  </div>
                )
              })}
            </div>


            {/* <div className={styles.titleTopContent}>
              <div className={styles.titleTag}>
                {homeData?.labelList?.map((v: any) => {
                  return <span className={styles.titleTagText}>{v?.info}</span>
                })}
              </div>
              <div
                className={styles.titleTagRightText}
                onClick={() =>
                  history.push({
                    pathname: '/clinicOverview',
                    state: {
                      name: 1,
                    },
                  })
                }
              >
                <span>诊所概况</span>
                <img
                  src={rightIconArrow}
                  alt=''
                  style={{
                    width: '16px',
                    height: '16px',
                    marginLeft: '4px',
                    marginTop: '2px',
                  }}
                />
              </div>
            </div> */}
            {/* <div className={styles.titleBottomContent}> */}
            {/* 左边地址 */}
            {/* <div className={styles.titleBottomLeftText}> */}
            {/* {homeData?.address} */}
            {/* </div> */}
            {/* 右边文字 */}
            {/* <div className={styles.titleBottomRightContent}>
                <div
                  className={styles.titleBottomRightTextOne}
                  onClick={() => {
                    wx.openLocation({
                      latitude: Number(homeData?.latitude), // 纬度，浮点数，范围为90 ~ -90
                      longitude: Number(homeData?.longitude), // 经度，浮点数，范围为180 ~ -180。
                      name: homeData?.name, // 位置名
                      address: homeData?.address, // 地址详情说明
                      scale: 14, // 地图缩放级别,整型值,范围从1~28。默认为最大
                        success(res:any) {
                            alert(JSON.stringify(res))
                        },
                        fail(e:any) {
                            alert(JSON.stringify(e))
                        }
                    })
                  }}
                >
                  <div
                    style={{
                      height: '20px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src={rightIconArea}
                      alt=''
                      style={{
                        width: '14px',
                        height: '14px',
                      }}
                    />
                  </div>
                  <div>导航</div>
                </div>
                <div
                  className={styles.titleBottomRightText}
                  onClick={() => {
                    setVisible(true)
                  }}
                >
                  <div
                    style={{
                      height: '20px',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src={rightIconPhone}
                      alt=''
                      style={{
                        width: '17px',
                        height: '17px',
                      }}
                    />
                  </div>
                  <div>电话</div>
                </div>
              </div> */}
            {/* </div> */}
          </div>
        </Card>
      </div>
      {/* <div className={styles.centerImg}>

      </div> */}
      {/* <div className={styles.centerContentProduce}>
        <div
          className={styles.centerContentLeftProduce}
          onClick={() => {
            history.push({
              pathname: '/onlineAppointment',
            })
          }}
        >
          <img
            src={centerBackLeftIcon}
            alt=''
            style={{
              width: '63px',
              height: '63px',
            }}
            className={styles.centerBackIcon}
          />
          <img
            src={centerLeftIcon}
            alt=''
            style={{
              width: '24px',
              height: '24px',
              margin: '2px 10px 0 4px',
            }}
          />
          <span
            style={{ color: '#ffffff', fontSize: '18px', fontWeight: 'bold' }}
          >
            在线预约
          </span>
        </div>
        <div
          className={styles.centerContentRightProduce}
          onClick={() => {
            history.push({
              pathname: '/clinicOverview',
              // state: {
              //   name: 1,
              // },
            })
          }}
        >
          <img
            src={centerBackRightIcon}
            alt=''
            style={{
              width: '60px',
              height: '60px',
            }}
            className={styles.centerBackIcon}
          />
          <img
            src={centerRightIcon}
            alt=''
            style={{
              width: '24px',
              height: '24px',
              margin: '2px 10px 0 4px',
            }}
          />
          <span
            style={{ color: '#ffffff', fontSize: '18px', fontWeight: 'bold' }}
          >
            诊所介绍
          </span>
        </div>
      </div> */}
      {doctorDetail?.length ? (
        <Card className={styles.bottomList}>
          <Tabs>
            <Tabs.Tab title='医生团队' key='1'>
              <span className={styles.more} onClick={() => {
                history.push({
                  pathname: '/doctorList'
                })
              }}>更多</span>
              {doctorDetail?.map((v: any, index:number) => {
                return (
                  <div className={styles.bottomContent}
                    onClick={() =>
                      history.push({
                        pathname: '/doctorDetail',
                        state: {
                          id: v?.id,
                        },
                      })
                    } style={{ borderBottom: index == doctorDetail?.length - 1 ? 'none' : '1px solid rgba(238, 238, 238, 1)'}}>
                    <div className={styles.bottomContentLeftImg}>
                      <img
                        src={
                          v?.headPhoto
                            ? v?.headPhoto
                            : v?.sex == 2
                              ? girlHeadImg
                              : boyHeadImg
                        }
                        alt=''
                        style={{
                          width: '50px',
                          height: '50px',
                          marginRight: '16px',
                          borderRadius: '50%',
                        }}
                      />
                    </div>
                    <div
                      className={styles.bottomContentRightMain}
                    >
                      <div className={styles.bottomContentRightMainTitLe}>
                        <div className={styles.doctorName}>
                          {v?.realname || '-'}
                        </div>
                        <div className={styles.doctorProduce}>
                          <div style={{ marginRight: '10px' }}>
                            {v?.outpatientDepartmentName || '-'}
                          </div>
                          <div>{v?.jobTitle || '-'}</div>
                        </div>
                      </div>
                      <div className={styles.bottomContentRightMainContent}>
                        擅长：{v?.adeptAt || '-'}
                      </div>
                    </div>
                  </div>
                )
              })}
            </Tabs.Tab>
          </Tabs>
        </Card>
      ) : ''}
      <Card className={styles.bottomList}>
        <Tabs>
          <Tabs.Tab title='健康科普' key='2'>
            <div className={styles.bottomContent} onClick={() => {
              history.push({
                pathname: '/artical',
                state: {
                  id: 1
                }
              })
            }}>
              <div style={{ width: '90px' }}>
                <img
                  src={articalOne}
                  alt=''
                  style={{
                    width: '90px',
                    height: '70px',
                    marginRight: '7px',
                    borderRadius: '5px',
                  }}
                />
              </div>
              <div
                className={styles.bottomContentRightMain} style={{ marginLeft: '10px', flex: 1 }}>
                <div className={styles.bottomContentRightMainTitLeArtical}>
                  <Ellipsis direction='end' content='30个医学常识' />
                </div>
                <div className={styles.bottomContentRightMainContentTwo}>
                  健康成年人安静状态下，心率平均为每分钟75次。正常范围为每分钟60-100次。成人安静时心率超过100次/分钟，为心动过速；低于60次/分钟者，为心动过缓。心率可因年龄、性别及其他因素而变化，比如体温每升高1℃，心率可加快12-20次/分钟
                </div>
              </div>
            </div>
            <div className={styles.bottomContent} onClick={() => {
              history.push({
                pathname: '/artical',
                state: {
                  id: 2
                }
              })
            }}>
              <div style={{ width: '90px' }}>
                <img
                  src={articalTwo}
                  alt=''
                  style={{
                    width: '90px',
                    height: '70px',
                    marginRight: '7px',
                    borderRadius: '5px',
                  }}
                />
              </div>
              <div
                className={styles.bottomContentRightMain} style={{ marginLeft: '10px', flex: 1 }}>
                <div className={styles.bottomContentRightMainTitLeArtical}>
                  <Ellipsis direction='end' content='医生才知道的40条健康知识，让你轻松掌握！' />
                </div>
                <div className={styles.bottomContentRightMainContentTwo}>
                  偶尔咬到舌头是正常现象，不是想吃肉了。经常会被咬着，常见出现的问题可能是中风，脑神经受压迫使舌不能灵活运动。
                </div>
              </div>
            </div>
            <div className={styles.bottomContent} style={{border: 'none'}} onClick={() => {
              history.push({
                pathname: '/artical',
                state: {
                  id: 3
                }
              })
            }}>
              <div style={{ width: '90px' }}>
                <img
                  src={articalThree}
                  alt=''
                  style={{
                    width: '90px',
                    height: '70px',
                    marginRight: '7px',
                    borderRadius: '5px',
                  }}
                />
              </div>
              <div
                className={styles.bottomContentRightMain} style={{ marginLeft: '10px' }}>
                <div className={styles.bottomContentRightMainTitLeArtical} >
                  <Ellipsis direction='end' content='关于身体的的35个健康常识，很多人现在还不知道' />
                </div>
                <div className={styles.bottomContentRightMainContentTwo}>
                  一个鼻孔出气是真的。在一整天里，也总是一个鼻孔处于工作状态，另一个鼻孔处于休息状态，这被称为鼻循环
                </div>
              </div>
            </div>
          </Tabs.Tab>
        </Tabs>
      </Card>
      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false)
        }}
        position='bottom'
        bodyStyle={{ height: '29vh', borderRadius: '5px 5px 0 0' }}
      >
        <div className={styles.popPhone}>{homeData?.phone}</div>
        <div
          className={styles.popCall}
          onClick={() => {
            window.location.href = 'tel:' + homeData?.phone
          }}
        >
          呼叫
        </div>
        <div className={styles.popBack}></div>
        <div
          className={styles.popCancel}
          onClick={() => {
            setVisible(false)
          }}
        >
          取消
        </div>
      </Popup>
    </div>
  )
}
